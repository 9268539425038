import auth from '@itau-loans-www/shopping/src/services/http/auth'
import { defaultLeadsApiCall } from '@itau-loans-www/shopping/src/services/http/utils'
import { storage } from 'utils'

const getSmsToken = async () => {
  const { data } = await defaultLeadsApiCall({
    endpoint: 'otp-send-sms-code',
    method: 'get'
  })

  return data
}

const sendSmsToken = async (requestBody, otpToken) => {
  const tokensAtstore = storage.session.getItem('token')

  const { data } = await auth({}).then((_http) => {
    _http.defaults.headers.common['OTP-Token'] = `${otpToken}`

    return _http.post(
      `v2/itau-360-shopping-api/leads/${tokensAtstore?.leadId}/otp-validate`,
      requestBody
    )
  })

  const authentication = data?.auth

  const token = {
    ...tokensAtstore,
    accessToken: authentication['token'],
    startDate: authentication['server_time'],
    validTime: authentication['valid_time']
  }

  storage.session.setItem('token', JSON.stringify(token))

  return data
}

const getStepInfo = async () => {
  const { data } = await defaultLeadsApiCall({
    endpoint: 'step_info',
    method: 'get'
  })

  return data
}

const getLeadPhone = async (args) => {
  const isToRemoveHash = args?.removeHash
  const hashFlag = isToRemoveHash ? '?hash=false' : ''

  const { data } = await defaultLeadsApiCall({
    endpoint: `send_sms_code/${hashFlag}`,
    method: 'get'
  })

  return data
}

export { getSmsToken, getStepInfo, sendSmsToken, getLeadPhone }
