import { useEffect, useState } from 'react'

import { OTP_WEB_COMPONENT_URL } from 'gatsby-env-variables'

import { useLead } from '@itau-loans-www/shopping/src/hooks'
import analytics from '@mobi/libraries/analytics'
import { addScript, goTo } from 'utils'

import { DDPageError } from 'utils/datadog/events'

import {
  getSmsToken,
  getLeadPhone,
  getStepInfo,
  sendSmsToken
} from '../services'

export const useOTPToken = () => {
  const [otpToken, setOtpToken] = useState('')
  const [lastFourCellphoneNumbers, setLastFourCellphoneNumbers] = useState('')
  const { changeLeadData } = useLead()

  useEffect(() => {
    const getOtpMFEData = async () => {
      try {
        const { token, previous_step } = await getSmsToken()
        setOtpToken(token)

        const data = await getLeadPhone({
          removeHash: true
        })

        changeLeadData({
          previousStep: previous_step
        })

        setLastFourCellphoneNumbers(data?.lead_phone_number.slice(-4))
      } catch (error) {
        const errorPayload = {
          error: error?.message,
          requestURL: error?.request?.responseURL
        }

        console.error('OTP getOtpMFEData |', errorPayload)

        DDPageError({
          pageName: 'OTP',
          name: 'getOtpMFEData',
          payload: errorPayload
        })
      }
    }

    addScript(OTP_WEB_COMPONENT_URL)
    getOtpMFEData()
  }, [])

  useEffect(() => {
    window.addEventListener(`onOTPPostValidate${otpToken}`, (e) => {
      const status = e.detail.status
      const isSuccess = status === 'Success'
      const isError = status === 'Error'

      if (isSuccess) {
        onSubmit()
        return
      }

      const unsuccessfulPayload = {
        detail: e?.detail,
        status
      }

      console.error('OTP onOTPPostValidate |', unsuccessfulPayload)

      if (isError) {
        DDPageError({
          pageName: 'OTP',
          name: 'onOTPPostValidate',
          payload: unsuccessfulPayload
        })
      }
    })

    return () => {
      window.removeEventListener(`onOTPPostValidate${otpToken}`, onSubmit)
    }
  }, [otpToken])

  const onSubmit = async () => {
    const requestBody = {
      sms_code: window.otpCode
    }

    analytics.track('buttonClick', {
      currentPage: 'novo-formulario-sms-otp',
      detail: 'click-step-new-otp-sms'
    })

    try {
      await sendSmsToken(requestBody, otpToken)

      const { next_step, final_screen } = await getStepInfo()

      if (final_screen) return goTo(final_screen)

      goTo(next_step)
    } catch (error) {
      const errorPayload = {
        error: error?.message,
        requestURL: error?.request?.responseURL
      }

      console.error('OTP onSubmit |', errorPayload)

      DDPageError({
        pageName: 'OTP',
        name: 'onSubmit',
        payload: errorPayload
      })
    }
  }

  return {
    otpToken,
    lastFourCellphoneNumbers
  }
}
