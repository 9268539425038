import { createElement } from 'react'

import ProposalLayout from 'layouts/Proposal'

import './sms-token.scss'
import { useOTPToken } from './useOTP'

const ItauOTP = ({ otpToken, lastFourCellphoneNumbers }) => {
  return createElement('itau-otp', {
    token: otpToken,
    phoneNumber: lastFourCellphoneNumbers,
    hasDefaultButton: true
  })
}

const OTP = ({ pageContext }) => {
  const { otpToken, lastFourCellphoneNumbers } = useOTPToken()

  return (
    <ProposalLayout pageContext={pageContext}>
      <form className="sms-token">
        <ItauOTP
          otpToken={otpToken}
          lastFourCellphoneNumbers={lastFourCellphoneNumbers}
        />
      </form>
    </ProposalLayout>
  )
}

export default OTP
